import { useState } from 'react'
import { IPaginationParams, UsePagination } from './usePagination.types'

export const usePagination: UsePagination = (options) => {
  const { initialRowsPerPage = 10 } = options
  const [paginationParams, setPaginationParams] = useState<IPaginationParams>({
    page: 0,
    pageSize: initialRowsPerPage,
    searchValue: '',
  })

  const onChangePagination = (pageNumber: number, itemsPerPage: number) => {
    setPaginationParams({ page: pageNumber - 1, pageSize: itemsPerPage, searchValue: '' })
  }

  return {
    paginationParams,
    setPaginationParams,
    onChangePagination,
  }
}
