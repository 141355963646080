import { useHasPermission } from 'admin-portal-shared-services'
import React from 'react'

interface Props {
  children: React.ReactElement
  permission: string | string[]
  isEnabled?: boolean
}

export const ProtectedRoute = ({ isEnabled = false, children, permission }: Props) => {
  const hasUserPermission = useHasPermission(permission, 'OR')
  return isEnabled && hasUserPermission ? <>{children}</> : null
}
