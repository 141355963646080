import enZA from './languages/en-ZA.json'
import ptBR from './languages/pt-BR.json'
import enCA from './languages/en-CA.json'
import frCA from './languages/fr-CA.json'
import esAR from './languages/es-AR.json'

export type Language = 'en-ZA' | 'pt-BR' | 'en-CA' | 'fr-CA' | 'es-419'

export const defaultNS = 'common'

export const resources = {
  'en-ZA': enZA,
  'pt-BR': ptBR,
  'en-CA': enCA,
  'fr-CA': frCA,
  'es-419': esAR,
} as const
