import { Paragraph } from '@hexa-ui/components'
import { styled } from '@hexa-ui/theme'
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu'

export const DropdownMenuRoot = styled(RadixDropdownMenu.Root, {})

export const DropdownMenuTrigger = styled(RadixDropdownMenu.Trigger, {
  outline: 'none',
})

export const DropdownMenuContent = styled(RadixDropdownMenu.Content, {
  background: '$neutral0',
  zIndex: '$10',
  borderRadius: '$4',
  boxShadow: '$4',
  overflow: 'hidden',
  padding: '0.25rem 0',
})

export const DropdownMenuItem = styled(RadixDropdownMenu.Item, {
  padding: '1rem',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$brandAccentNeutralBackground',
    outline: 'none',
  },
})

export const ItemContainer = styled('div', {
  display: 'flex',
})

export const ItemLabel = styled(Paragraph, {
  marginLeft: '$2',
})
