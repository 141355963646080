import axios from 'axios'

export const convertImageUrlToFile = async (imageUrl?: string): Promise<File | null> => {
  try {
    if (imageUrl) {
      const response = await axios.get(imageUrl, {
        responseType: 'arraybuffer',
      })
      const blob = new Blob([response.data])
      const fileName = 'image'
      const imageFile = new File([blob], fileName)
      return imageFile
    }
    return null
  } catch (error) {
    return null
  }
}
