import { IconButton } from '@hexa-ui/components'
import * as Styles from './DropdownMenu.styles'
import { IDropdownMenuProps } from './DropdownMenu.types'

export const DropdownMenu = ({ icon, items }: IDropdownMenuProps) => (
  <Styles.DropdownMenuRoot modal={false}>
    <Styles.DropdownMenuTrigger asChild>
      <IconButton icon={icon} variant="inherit" />
    </Styles.DropdownMenuTrigger>
    <Styles.DropdownMenuContent>
      {items.map(({ icon: Icon, label, onClick }) => (
        <Styles.DropdownMenuItem asChild key={label} aria-label={label} onClick={onClick}>
          <Styles.ItemContainer>
            <Icon />
            <Styles.ItemLabel>{label}</Styles.ItemLabel>
          </Styles.ItemContainer>
        </Styles.DropdownMenuItem>
      ))}
    </Styles.DropdownMenuContent>
  </Styles.DropdownMenuRoot>
)
