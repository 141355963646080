import { styled } from '@hexa-ui/theme'

export const PageLoaderWraper = styled('div', {
  position: 'absolute',
  minHeight: '100vh',
  minWidth: '100vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  left: 0,
  background: '$neutral10',
})
