import { useAnalyticsService } from 'admin-portal-shared-services'
import { useEffect } from 'react'

export const useSegmentAnalytics = (segmentKey: string): void => {
  const analyticsService = useAnalyticsService()

  useEffect(() => {
    analyticsService.load(segmentKey)
  }, [analyticsService, segmentKey])
}
