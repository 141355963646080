import {
  ContainerDataProvider,
  EnvironmentProvider,
  EnvConfig,
  I18nProvider,
  ThemeProvider,
} from '@providers'
import { PropsWithChildren } from 'react'

export function GlobalProvider({ children, ...props }: PropsWithChildren<EnvConfig>) {
  return (
    <ThemeProvider>
      <EnvironmentProvider env={props}>
        <I18nProvider>
          <ContainerDataProvider>{children}</ContainerDataProvider>
        </I18nProvider>
      </EnvironmentProvider>
    </ThemeProvider>
  )
}
