import { EnvConfig, GlobalProvider } from '@providers'
import { useSegmentAnalytics } from '@hooks'
import { Router } from './routes'

export default function App(props: EnvConfig) {
  const { SEGMENT_KEY } = props
  useSegmentAnalytics(SEGMENT_KEY)

  return (
    <GlobalProvider {...props}>
      <Router />
    </GlobalProvider>
  )
}
