import { useEnvService } from 'admin-portal-shared-services'
import { createContext, useEffect, useMemo, useState } from 'react'

export interface EnvConfig {
  SEGMENT_KEY: string
  name: string
}

interface EnvContext extends EnvConfig {
  baseUrl: string
  cmsBaseUrl: string
}

export const EnvironmentContext = createContext<EnvContext>({
  baseUrl: '',
  cmsBaseUrl: '',
  SEGMENT_KEY: '',
  name: '',
})

export const PROD_BASE_URL = 'https://one.bees-platform.com'
export const UAT_BASE_URL = 'https://one-uat.bees-platform.dev'
export const SIT_BASE_URL = 'https://one-sit.bees-platform.dev'

export const PROD_CMS_BASE_URL = 'https://cms-services.bees-platform.com'
export const UAT_CMS_BASE_URL = 'https://cms-services-uat.bees-platform.dev'
export const SIT_CMS_BASE_URL = 'https://cms-services-sit.bees-platform.dev'

export function EnvironmentProvider({
  env: { SEGMENT_KEY, name },
  children,
}: {
  env: EnvConfig
  children: React.ReactNode
}) {
  const [baseUrl, setBaseUrl] = useState('')
  const [cmsBaseUrl, setCmsBaseUrl] = useState('')
  const environment = useEnvService().getEnv()

  useEffect(() => {
    let baseUrlEnv = ''
    let cmsBaseUrlEnv = ''

    switch (environment) {
      case 'PROD':
        baseUrlEnv = PROD_BASE_URL
        cmsBaseUrlEnv = PROD_CMS_BASE_URL
        break
      case 'UAT':
        baseUrlEnv = UAT_BASE_URL
        cmsBaseUrlEnv = UAT_CMS_BASE_URL
        break
      default:
        baseUrlEnv = SIT_BASE_URL
        cmsBaseUrlEnv = SIT_CMS_BASE_URL
        break
    }

    setBaseUrl(baseUrlEnv)
    setCmsBaseUrl(cmsBaseUrlEnv)
  }, [environment])

  const value = useMemo(
    () => ({ baseUrl, cmsBaseUrl, SEGMENT_KEY, name }),
    [baseUrl, cmsBaseUrl, SEGMENT_KEY, name]
  )

  return <EnvironmentContext.Provider value={value}>{children}</EnvironmentContext.Provider>
}
