import { AppHeaderConfigPayload } from 'admin-portal-shared-services'
import { t } from 'i18next'
import { ModulesPages } from 'routes/Definition'

export const defaultAppHeaderConfig: AppHeaderConfigPayload = {
  pageTitle: ' ',
  breadcrumbConfig: {
    homePath: '',
    items: [],
  },
  vendorSelect: false,
  countrySelect: false,
  countryOptions: [],
}

export const getAppHeaderConfig: (
  pageName: ModulesPages
) => Promise<AppHeaderConfigPayload> = async (pageName) => {
  const { homePath, campaignsCreatePath } = await import('routes/Definition')

  switch (pageName) {
    case 'CAMPAIGNS':
      return defaultAppHeaderConfig
    case 'CAMPAIGNS_CREATE':
      return {
        pageTitle: t('create.title'),
        breadcrumbConfig: {
          homePath,
          items: getItemsCampaignsCreate(homePath, campaignsCreatePath),
        },
      }
  }
}

const getItemsCampaignsCreate = (path: string, pathCampaignsCreate) => [
  {
    isCurrentPage: true,
    path: pathCampaignsCreate,
    label: t('create.title'),
  },
]
