import { PageLoader, ProtectedRoute } from '@components'
import { Megaphone } from '@hexa-ui/icons'
import { Namespaces } from 'i18next'
import { ElementType, LazyExoticComponent, ReactElement, Suspense, lazy } from 'react'

export type Modules = 'CAMPAIGNS'
export type ModulesPages = Modules | 'CAMPAIGNS_CREATE'

type Route = {
  path: string
  Element: LazyExoticComponent<() => ReactElement>
  children?: Route[]
}

type RoutesProps = Record<ModulesPages, Route>

type SidebarProps = Record<Modules, { id: Namespaces; path: string; icon: ElementType }>

export const CampaignsPage = lazy(() => import('pages/Campaigns'))
export const CampaignsCreatePage = lazy(() => import('pages/Campaigns/Create'))

export const BASE_NAME = 'campaigns'

export const homePath = `/${BASE_NAME}`
export const campaignsCreatePath = `${homePath}/create`

export const Routes: RoutesProps = {
  CAMPAIGNS: { path: homePath, Element: CampaignsPage },
  CAMPAIGNS_CREATE: { path: campaignsCreatePath, Element: CampaignsCreatePage },
}

export const userPermissions = {
  read: 'LinkAdmin.CampCrea.Read',
  write: 'LinkAdmin.CampCrea.Write',
  all: ['LinkAdmin.CampCrea.Read', 'LinkAdmin.CampCrea.Write'],
}

export const Sidebar: SidebarProps = {
  CAMPAIGNS: {
    id: 'common',
    path: homePath,
    icon: () => <Megaphone />,
  },
}

export const sidebar = [Sidebar.CAMPAIGNS]

export const modules = [Routes.CAMPAIGNS, Routes.CAMPAIGNS_CREATE]

export const routesConfig = modules.map(({ path, Element }) => ({
  path,
  element: (
    <Suspense fallback={<PageLoader />}>
      <ProtectedRoute isEnabled permission={userPermissions.all}>
        <Element />
      </ProtectedRoute>
    </Suspense>
  ),
}))
