interface DownloadFileProps {
  url: string
  fileName: string
  fileExt: string
  fileType?: string
}

export function downloadFile({ url, fileName, fileExt }: DownloadFileProps): void {
  const downloadLink = document.createElement('a')
  downloadLink.href = url
  downloadLink.download = `${fileName}.${fileExt}`
  downloadLink.click()
}
