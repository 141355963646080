import { ContainerDataProvider, I18nProvider } from '@providers'
import { RenderOptions, render } from '@testing-library/react'
import { ReactNode } from 'react'
import { MemoryRouter, MemoryRouterProps } from 'react-router-dom'

export const renderComponent = (
  component: ReactNode,
  memoryRouterProps?: MemoryRouterProps,
  renderOptions?: RenderOptions
) => {
  return render(
    <I18nProvider>
      <ContainerDataProvider>
        <MemoryRouter {...memoryRouterProps}>{component}</MemoryRouter>
      </ContainerDataProvider>
    </I18nProvider>,
    { ...renderOptions }
  )
}
