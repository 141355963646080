import { Grid } from '@hexa-ui/components'
import { defaultAppHeaderConfig, getAppHeaderConfig } from '@utils'
import { useAppHeader, useAuthenticationService } from 'admin-portal-shared-services'
import { useEffect } from 'react'
import { useDynamicSidebar } from 'hooks/useDynamicSidebar'
import { PageWrapperProps } from './PageWrapper.types'

export const PageWrapper = ({
  children,
  showBreadcrumb = true,
  pageName = 'CAMPAIGNS',
  showCountryVendorSelec = false,
  dataTestId = 'default',
  className = 'pageWrapper',
}: PageWrapperProps) => {
  const [, setAppHeaderConfig] = useAppHeader()
  const authentication = useAuthenticationService()

  useEffect(() => {
    const setBreadcrumb = async () => {
      let appHeaderConfig = {
        ...defaultAppHeaderConfig,
        defaultCountry: authentication.getCountryB2C(),
      }

      if (showBreadcrumb) {
        appHeaderConfig = {
          ...appHeaderConfig,
          ...(await getAppHeaderConfig(pageName)),
          countryOptions: authentication.getSupportedCountries(),
        }
      }

      if (showCountryVendorSelec) {
        appHeaderConfig = {
          ...appHeaderConfig,
          vendorSelect: true,
          countrySelect: true,
        }
      }

      setAppHeaderConfig(appHeaderConfig)
    }

    setBreadcrumb()
  }, [pageName, setAppHeaderConfig, showBreadcrumb, showCountryVendorSelec, authentication])

  useDynamicSidebar()

  return (
    <Grid.Container className={className} sidebar type="fluid" data-testid={dataTestId}>
      {children}
    </Grid.Container>
  )
}
